<template>
  <div>
    <el-card class="box-card card-contanier"   v-if="ranks === 0 " >
      <div class="card-content"></div>
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div style="float: left; width: 94%">

            <el-form-item label="公众号名称：">
              <el-input
                v-model="searchForm.wxPublic"
                placeholder="公众号名称"
                size="medium"
                clearable>
              </el-input>
            </el-form-item>

            <!-- <el-form-item label="微信号：">
              <el-input
                v-model="searchForm.wxAccount"
                placeholder="微信号"
                size="medium"
                clearable>
              </el-input>
            </el-form-item> -->

            <el-form-item>
              <el-button @click="search" type="primary" icon="el-icon-search" size="small">搜索</el-button>
            </el-form-item>

            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium">重置</el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary"  size="medium" @click="dialogVisible = true"  v-if="hasAuth('sys:weChat:add')"  >新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      border
      stripe>

      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        align="center" >
      </el-table-column>

      <el-table-column
        prop="alName"
        label="所属商盟"
        align="center">
      </el-table-column>

      <el-table-column
        prop="wxPublic"
        label="公众号名称"
        align="center">
      </el-table-column>

      <el-table-column
        prop="wxPrimeId"
        label="公众号原始Id"
        align="center">
      </el-table-column>

      <el-table-column
        prop="wxAccount"
        label="微信号"
        align="center">
      </el-table-column>

      <el-table-column prop="wxPubType" label="公众号类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.wxPubType == '0'"> 服务号 </span>
          <span v-else-if="scope.row.wxPubType == '1'"> 订阅号 </span>
        </template>
      </el-table-column>

       <el-table-column prop="wxTrans" label="是否认证" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.wxTrans == '0'"> 已认证 </span>
          <span v-else-if="scope.row.wxTrans == '1'"> 未认证 </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="wxAppid"
        label="微信appId"
        align="center" >
      </el-table-column>

      <el-table-column 
        prop="wxAppsecret" 
        label="微信appsecret" 
        align="center">
      </el-table-column>

      <el-table-column
        prop="icon"
        label="操作"
        width="200"
        align="center"
        fixed="right" 
        v-if="ranks === 0 || ranks === 1 ">
        <template slot-scope="scope">
          <el-button @click="editHandler(scope.row.wxId)" type="text" size="small"  v-if="hasAuth('sys:wechat:edit')">编辑</el-button >
          
          <template v-if="hasAuth('sys:wechat:delete')">
            <el-divider direction="vertical"></el-divider>
            <el-popconfirm
              title="确认删除当前选中数据？"
              @confirm="delHandle(scope.row.wxId)">
              <el-button type="text" slot="reference" >删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!---分页-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total">
    </el-pagination>

    <!--新增对话框  -->
    <el-dialog
      title="公众号管理"
      :visible.sync="dialogVisible"
      width="28%"
      :before-close="handleClose"
      :close-on-click-modal="false">

      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm">

        <el-form-item 
          label="商盟:"
          prop="meId"
          label-width="120px"
          style="width: 90%">
            <el-select v-model="editForm.meId" placeholder="请选择商盟"  label-width="100px"  :disabled="flag"  style="width: 100%">
              <el-option
                v-for="item in alIdOptions "
                :key="item.al_id"
                :label="item.al_name"
                :value="item.al_id">
              </el-option>
            </el-select>
        </el-form-item>
        
        <el-form-item
          label="公众号名称:"
          prop="wxPublic"
          label-width="120px"
          style="width: 90%">
          <el-input v-model="editForm.wxPublic" auto-complete="off"> </el-input>
        </el-form-item>
        
        <el-form-item
          label="公众号原始Id:"
          prop="wxPrimeId"
          label-width="120px"
          style="width: 90%" >
          <el-input v-model="editForm.wxPrimeId" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="微信号:"
          prop="wxAccount"
          label-width="120px"
          style="width: 90%">
          <el-input v-model="editForm.wxAccount" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="微信appid:"
          prop="wxAppid"
          label-width="120px"
          style="width: 90%">
          <el-input v-model="editForm.wxAppid" auto-complete="off"> </el-input>
        </el-form-item>

        <el-form-item
          label="微信appsecret:"
          prop="wxAppsecret"
          label-width="120px"
          style="width: 90%">
          <el-input v-model="editForm.wxAppsecret" auto-complete="off"> </el-input>
        </el-form-item>


        <el-form-item
          label="公众号类型:"
          prop="wxPubType"
          label-width="120px"
          style="margin-top: 20px">
          <el-radio-group v-model="editForm.wxPubType">
            <el-radio label="0">服务号</el-radio>
            <el-radio label="1">订阅号</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="是否认证:"
          prop="wxTrans"
          label-width="120px"
          style="margin-top: 20px">
          <el-radio-group v-model="editForm.wxTrans">
            <el-radio :label="'0'">是</el-radio>
            <el-radio :label="'1'">否</el-radio>
          </el-radio-group>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer" style=" ">
        <el-button type="primary" @click="submitForm('editForm')">保 存</el-button>
        <el-button  @click="handleClose">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/user";
export default {
  name: "AssetType",
  data() {
    return {
     
      ranks:'',
      alIdOptions: [], //联盟选择框
      dialogVisible: false,
      editForm: {},
      total: 0,
      size: 10,
      current: 1,
      flag:false,
      searchForm:{
        wxPublic:'',
      },
   
      //isAdd: true,
      //nohigh: true,
      //activeName: `first`,
      //fileList: "",
    
      //imageUrl: "",
     
     // Level1: "2",
      // show: true,
      // isShow: false,
      // dictsAssetProvinceclass: [],
      // dictsAssetCityclass: [],
      // dictsAssetquclass: [],
     // Level2: "1",
      editFormRules: {
        meId:[
          {required: true, message: "请选择商盟", trigger: "blur"}
        ],
        wxPublic: [
          { required: true, message: "公众号名称", trigger: "blur" }
        ],
        wxPrimeId: [
          { required: true, message: "公众号原始Id", trigger: "blur" }
        ],
        wxAccount: [{ required: true, message: "微信号", trigger: "blur" }
        ],
        wxAppid: [
          { required: true, message: "微信appid", trigger: "blur" },
        ],
        wxPubType: [
          { required: true, message: "公众号类型", trigger: "blur" },
        ],
        wxTrans: [
          { required: true, message: "是否认证", trigger: "blur" },
        ],
        wxAppsecret:[
          { required: true, message: "微信appsecret", trigger: "blur"}
        ],
      },
      tableData: [],
      url: {
        list: "/sy/wechatbasic/list",
        // selectById: "/sys/time/selectById",
        // deleteById: "/sys/time/deleteById",
      },
    };
  },
  created() {
    this.getUserInfo();
    this.getProjectList();
    this.getlistAlliance();
  },
  methods: {
    async getUserInfo() {
        await api.userinfo().then((res) => {
          this.ranks = res.data.data.result.ranks;
          // console.log(res.data.data.result.ranks);
          if (res.data.data.result.ranks === 1 ) {
            this.flag = true;
          }
          // if(res.data.data.result.ranks === 0){
          //   this.flag = false;
          // }
        });
      },
    getlistAlliance() {
        api.getlistAlliance().then((res) => {
          this.alIdOptions = res.data.data.result;
        });
      },
    refresh() {
      this.searchForm = {
        wxPublic: "",
      };
      this.editForm = {};
      this.current = 1;
      this.getProjectList();
    },
  
    //列表数据查询
    getProjectList() {
      api.WXlist({
        params: {
          wxPublic: this.searchForm.wxPublic,
            current: this.current,
            size: this.size,
          },
      } ).then((res) => {
          this.tableData = res.data.data.result.records;
          this.size = res.data.data.result.size;
          this.current = res.data.data.result.current;
          this.total = res.data.data.result.total;
       
        });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getProjectList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getProjectList();
    },
    indexMethod(index) {
      return index + (this.current - 1) * 10 + 1;
    },
    search() {
      this.current = 1;
      this.getProjectList();
    },
    //新增和修改的保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let status = this.editForm.wxId ? "update" : "save";
          api.saveWeChat(status, this.editForm).then((res) => {
            this.$notify({
              title: "prefect",
              message: "操作成功",
              position: "bottom-right",
              type: "success",
              onClose: () => {
                this.getProjectList();
              },
            });
            this.dialogVisible = false;
            this.refresh();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改时的回显
    editHandler(wxId) {
      this.flag = true;
      api.selectWeChatHx({
          wxId: wxId,
        }).then((res) => {
          this.editForm = res.data.data.result[0];
          this.dialogVisible = true;

        });
      
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
      this.dialogVisible = false;
      this.editForm = {};
      this.flag=false;
    },
    //根据id删除
    delHandle(wxId) {
      api.deleteWeChat(wxId).then((res) => {
        this.getProjectList();
        this.$notify({
          title: "prefect",
          message: "操作成功",
          type: "success",
          position: "bottom-right",
        });
      });
    },
  },
};
</script>

<style scoped>
.card-contanier {
  margin-bottom: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.map {
  width: 80%;
  height: 300px;
}
.el-pagination {
  float: right;
  margin-top: 22px;
}
</style>
